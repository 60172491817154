import * as React from 'react';

const Banner: React.FC = () => {
	return (
		<section className="bg-black">
			<div className="mx-auto my-0 w-full max-w-[1100px] pt-[64px] pb-[80px] max-[680px]:w-[420px] max-[480px]:w-[300px] max-[320px]:w-[90%]">
				<h1 className="w-full text-center font-oswald text-[48px] tracking-[3px] text-dirtcheaptext max-md:text-[30px]">
					START AN EXCITING CAREER AT DIRT CHEAP
				</h1>
			</div>
		</section>
	);
};

export default Banner;
