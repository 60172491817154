import * as React from 'react';
import { graphql, PageProps, useStaticQuery } from 'gatsby';
import Layout from '../../layouts/Layout';
import Banner from '../../components/Hero/Banner';
import JobSearch from '../../components/JobSearch/JobSearch';
// @ts-ignore
import backArrow from './../../images/red-arrow-left.svg';
const SearchPage: React.FC<PageProps> = () => {
	const data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`);
	return (
		<Layout
			title="Dirt Cheap | Employment Opportunities"
			desc="Dirt Cheap | Employment Opportunities"
			// media={Media}
			// mediaContextProvider={MediaContextProvider}
			// logoSrc={mestekLogo}
			// menuItems={menuItems}
			// className={'search-page'}
		>
			<Banner />
			<section className={'px-[20px]'}>
				<div className={'container mx-auto max-w-[1176px] py-[48px]'}>
					<a
						href="/"
						className="max-w-[100px] text-[20px] text-dirtcheaptext hover:text-dirtcheaptext"
					>
						<button className="flex flex-row items-center">
							<img
								src={backArrow}
								width={16}
								className="mr-[6px]"
							/>
							Return to Map
						</button>
					</a>
					<h2
						className={'font-oswald text-[48px] text-dirtcheaptext'}
					>
						SEARCH RESULTS
					</h2>
				</div>
			</section>
			<JobSearch data={data} />
			{/* <SmallHero /> */}
			{/*<JobListSection />*/}
		</Layout>
	);
};
export default SearchPage;
